import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';

import { listDocuments } from 'graphql/queries';

export type Document = {
  id: string;
  patinetId: string;
  fileName: string;
  fileUrl: string;
  createdAt: string;
  updatedAt: string;
};

export type listDocumentsQuery = {
  listDocuments: Document[];
};

export const useDocumentsQuery = (patientId: string) => {
  return useQuery({
    queryKey: ['documents', patientId],
    queryFn: async () => {
      const result: GraphQLResult<listDocumentsQuery> = await API.graphql({
        query: listDocuments,
        variables: { patientId },
      });
      return result.data?.listDocuments;
    },
    enabled: !!patientId,
  });
};

export const useDocuments = (patientId: string) => {
  const { data } = useDocumentsQuery(patientId);
  return data ?? [];
};
