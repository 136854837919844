import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import { ThemeProvider } from '@mui/material/styles';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { PrivateRoute } from 'views/pages';
import { SignIn } from 'views/pages';
import { Dashboard } from 'views/pages';
import { darkTheme } from 'views/themes';

Amplify.configure({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: process.env.REACT_APP_S3_BUCKET,
  aws_user_files_s3_bucket_region: 'ap-northeast-1',
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    region: 'ap-northeast-1',
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    identityPoolRegion: 'ap-northeast-1',
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: 365,
      secure: true,
    },
  },
});

const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/signin" element={<SignIn />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
