import { useState } from 'react';
import { Box, Button, Fade, Modal } from '@mui/material';
import { css } from '@emotion/react';
import { Download } from '@mui/icons-material';

import { ModalPaper } from './ModalPaper';
import { usePatientsState } from 'hooks';
import { useDocuments } from 'hooks/useDocuments';

const buttonStyle = css`
  padding: 5px 10px;
  height: 40px;
  width: 220px;
  color: #333333;
  background-color: #f0ffff;
  &:hover {
    opacity: 0.8;
    background-color: #f0ffff;
  }
`;

export const PdfDownloadButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedPatientId } = usePatientsState();
  const documents = useDocuments(selectedPatientId);

  if (documents.length === 0) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)} startIcon={<Download />} css={buttonStyle}>
        <span
          css={css`
            font-size: 16px;
            font-weight: bold;
            font-family: 'Noto Sans JP', sans-serif;
            margin-bottom: 2px;
          `}
        >
          PDFダウンロード
        </span>
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Fade in={isOpen}>
          <Box>
            <ModalPaper files={documents} handleClose={() => setIsOpen(false)} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
