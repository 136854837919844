import { css } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { Button, Link, List, ListItem, Paper } from '@mui/material';

const boxStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  width: 560px;
  height: 560px;
  overflow-y: scroll;
  background-color: #292929;
  border-radius: 8px;
`;

const closeButtonStyle = css`
  position: absolute;
  top: 24px;
  right: 32px;
`;

export const ModalPaper: React.VFC<{
  files: { fileName: string; fileUrl: string }[];
  handleClose: () => void;
}> = ({ files, handleClose }) => {
  return (
    <Paper css={boxStyle} elevation={6}>
      <h5
        css={css`
          font-size: 1.75rem; /* 28px */
          line-height: 1;
          font-weight: bold;
          margin: 0;
        `}
      >
        PDFダウンロード
      </h5>
      <List
        css={css`
          overflow: auto;
        `}
      >
        {files.map((item) => (
          <ListItem key={item.fileUrl}>
            <Link
              href={item.fileUrl}
              download={item.fileName}
              target="_blank"
              rel="noreferrer"
            >
              {item.fileName}
            </Link>
          </ListItem>
        ))}
      </List>
      <Button
        variant="outlined"
        startIcon={<Close />}
        onClick={handleClose}
        css={closeButtonStyle}
      >
        閉じる
      </Button>
    </Paper>
  );
};
